<template>
	<div id="timer">

		<div class="clock">{{ clockValue }}</div>
		<div class="btn-stop">
			<b-button variant="primary" size="lg" @click="$store.dispatch('states/stopPause')">Pause beenden</b-button>
		</div>

	</div>
</template>

<script>

export default {
	name: "Timer",
	components: {},
	props: {},
	data: () => ({
		clockValue: '',
	}),
	created: function () {
	},
	mounted: function () {
		this.startClock();
	},
	computed: {
		enabled: function () {
			return this.$store.getters['states/isEnabled'];
		},
		started: function () {
			return this.$store.getters['states/getStarted'];
		},
	},
	methods: {
		initClock() {
			this.clockValue = '00:00';
		},
		startClock() {
			this.initClock();
			let timer = setInterval(() => {
				if (this.enabled) {
					let diff = Math.floor((new Date() / 1000) - this.started);
					let mins = Math.floor(diff / 60);
					let secs = (diff - mins * 60);
					this.clockValue = mins.toString().padStart(2, '0') + ':' + secs.toString().padStart(2, '0');
				} else {
					clearInterval(timer);
					this.initClock();
				}
			}, 1000);
		},

	},
}
</script>

<style scoped>

#timer {
	position: absolute;
	top: 0;
	left: 50%;
	transform: translate(-50%, 0);
	text-align: center;
}

.clock {
	height: 85px;
	line-height: 60px;
	font-size: 3rem;
	color: #444;
}

.btn-stop {
	font-size: 1.8rem;
}

.btn-stop button {
	width: 300px;
}


</style>