export default {

	// ----------------------------------------------------------------------------------------
	isProduction() {
		return process.env.NODE_ENV === 'production';
	},

	wakeLock: null,

	// ----------------------------------------------------------------------------------------
	requestFullscreen() {
		if (!this.isProduction()) return;

		let element = document.documentElement;
		if (element.requestFullscreen) element.requestFullscreen();
		else if (element.webkitRequestFullscreen) element.webkitRequestFullscreen();	/* Safari */
		else if (element.msRequestFullscreen) element.msRequestFullscreen(); 			/* IE11 */

		try {
			this.wakeLock = navigator.wakeLock.request('screen');
			console.info('Wake Lock is active!');
		} catch (error) {
			// The Wake Lock request has failed - usually system related, such as battery.
			console.info(error.message);
		}

	},

	// ----------------------------------------------------------------------------------------
	leaveFullscreen() {
		if (!this.isProduction()) return;

		if (document.exitFullscreen) document.exitFullscreen();
		else if (document.webkitExitFullscreen) document.webkitExitFullscreen();	/* Safari */
		else if (document.msExitFullscreen) document.msExitFullscreen();			/* IE11 */

		if (this.wakeLock != null) {
			this.wakeLock.release()
				.then(() => {
					this.wakeLock = null;
				});
		}
	},


}