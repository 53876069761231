import Vue from 'vue'
import App from './App.vue'
import VueAxios from 'vue-axios';
import VueCarousel from 'vue-carousel';

import axios from 'axios'
import store from './includes/store/store.js'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import {BootstrapVue, BootstrapVueIcons} from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

window.helpers = require('./includes/helpers.js').default;

Vue.use(VueAxios, axios);
Vue.use(VueCarousel);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

Vue.config.productionTip = false

new Vue({
	store,
	render: h => h(App),
}).$mount('#app');
