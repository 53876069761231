import Vue from 'vue';
import Vuex from 'vuex';
import {createStore} from "vuex-extensions";

// System
import states from "@/includes/store/states";

Vue.use(Vuex);

//export default new Vuex.Store({
export default createStore(Vuex.Store, {
	modules: {
		states,
	}
});
