<template>
	<div>
		<carousel
			class="carousel"
			:perPage="1"
			:navigationEnabled="true"
			navigationNextLabel="❯"
			navigationPrevLabel="❮"
		>

			<slide>
				<div class="slide-content">
					<h3>Richtig atmen</h3>
					Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
					sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
					sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
				</div>
			</slide>

			<slide>
				<div class="slide-content">
					<h3>Erklärung</h3>
					Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
					sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
					sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
				</div>
			</slide>

			<slide>
				<div class="slide-content">
					<h3>Tiefere Atmung</h3>
					Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
					sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
					sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
				</div>
			</slide>

			<slide>
				<div class="slide-content">
					<h3>Ziel</h3>
					Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
					sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
					sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
				</div>
			</slide>

			<slide>
				<div class="slide-content">
					<h3>Quellen</h3>
					Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
					sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
					sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
				</div>
			</slide>

			<slide>
				<div class="slide-content">
					<h3>Impressum</h3>
					<br>
					Kontakt: <b>wlsr.ch</b>
					<br>
					Version: <b>{{ version }}</b>
				</div>
			</slide>

		</carousel>
	</div>
</template>

<script>
import {version as packageVersion} from "../../package.json";

export default {
	name: "Intro",
	components: {},
	props: {},
	data: () => ({}),
	created: function () {
	},
	mounted: function () {
	},
	computed: {
		version: function () {
			return packageVersion;
		},
	},
	methods: {},
}
</script>

<style scoped>

.carousel {
	margin: 0 auto;
	width: 85%;
	border-radius: 5px;
	background-color: rgba(218, 237, 250, 0.5);
}

.slide-content {
	padding: 0 15px;
}

.slide-content h3 {
	margin-top: 16px;
	margin-bottom: 12px;
}

</style>

<style>

.VueCarousel-navigation-button {
	font-size: 16pt;
	font-weight: bold;
}

</style>