<template>
	<div id="pulser">
		<div v-if="$store.getters['states/isEnabled']" class="pulsating-circle"></div>
	</div>
</template>

<script>
export default {
	name: "Pulser",
	components: {},
	props: {},
	data: () => ({}),
	created: function () {
	},
	mounted: function () {
	},
	computed: {},
	methods: {},
}

/*
#pulser {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	height: 20em;
	width: 20em;
	background-color: rgba(56, 140, 193, 0.8);
	border-radius: 10em;
	border: solid 5px #a8c4e2;
}*/

</script>

<style scoped>

.pulsating-circle {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translateX(-50%) translateY(-50%);
	height: 20em;
	width: 20em;
}

/*
.pulsating-circle:before {
	content: '';
	position: relative;
	display: block;
	width: 24em;
	height: 24em;
	box-sizing: border-box;
	margin-left: -2em;
	margin-top: -2em;
	border-radius: 12em;
	background-color: rgba(1, 164, 233, 0.5);
	box-shadow: 0 0 4px 3px rgba(1, 164, 233, 0.5);
	animation: pulse-ring 10s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite;
}
*/

.pulsating-circle:after {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	display: block;
	width: 20em;
	height: 20em;
	border-radius: 10em;
	background-color: rgba(229, 245, 255, 0.8);
	box-shadow: 0 0 8px 3px rgba(229, 245, 255, 0.5);
	animation: pulse-dot 10s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite;
}

@keyframes pulse-ring {
	0% {
		transform: scale(0.42);
	}
	40% {
		transform: scale(0.9);
	}
	90%, 100% {
		transform: scale(0.42);
	}
}

@keyframes pulse-dot {
	0% {
		transform: scale(0.5);
	}
	20% {
		box-shadow: 0 0 8px 3px rgba(229, 245, 255, 0.5);
	}
	40% {
		transform: scale(1);
		box-shadow: 0 0 12px 12px rgba(1, 164, 233, 0.8);
	}
	80% {
		box-shadow: 0 0 8px 3px rgba(229, 245, 255, 0.5);
	}
	100% {
		transform: scale(0.5);
	}
}

</style>