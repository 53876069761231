import axios from "axios";

const state = {
	started: 0,
};
const getters = {
	isEnabled: (state) => {
		return state.started > 0;
	},
	getStarted: (state) => {
		return state.started;
	},
};

const actions = {
	startPause: function ({commit, dispatch}) {
		commit('setStarted', (new Date() / 1000));
		dispatch('notifyApi', 'PauseStarted');
		window.helpers.requestFullscreen();
	},
	stopPause: function ({commit, dispatch}) {
		commit('setStarted', 0);
		dispatch('notifyApi', 'PauseStopped');
		window.helpers.leaveFullscreen();
	},
	// eslint-disable-next-line no-unused-vars
	notifyApi: function ({commit}, path) {
		if (!window.helpers.isProduction()) return;

		axios.get('/api/?action=' + path).then().catch(error => {
			console.error(error)
		});
	},
};

const mutations = {
	setStarted(state, data) {
		state.started = data;
	},

};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
