<template>
	<div id="app" class="wrapper">

		<div class="header">
			<div class="banner">
				<Logo class="logo"/>
			</div>
		</div>


		<div v-if="$store.getters['states/isEnabled']">
			<div class="pulser">
				<Pulser/>
			</div>
			<div class="timer">
				<Timer/>
			</div>
		</div>


		<div v-else class="intro-block">
			<Intro class="intro-carousel"/>

			<b-button variant="primary" size="lg" @click="$store.dispatch('states/startPause')">Pause starten</b-button>
		</div>


	</div>
</template>

<script>
import Logo from "@/components/Logo";
import Intro from "@/components/Intro";
import Pulser from "@/components/Pulser";
import Timer from "@/components/Timer";

export default {
	name: 'App',
	components: {
		Logo,
		Intro,
		Pulser,
		Timer,
	},
	props: {},
	data: () => ({}),
	created: function () {
	},
	mounted: function () {
		this.$store.dispatch('states/notifyApi', 'AppLoaded');
	},
	computed: {},
	methods: {},
}
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed');

body {
	padding: 0;
	margin: 0;
	font-family: 'Roboto', sans-serif;
	background-color: #5999c6 !important;
	background-image: url('~@/assets/background-2.jpg');
	background-position: center;
	background-size: cover;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.wrapper {
	margin: 0 auto;
	min-height: 100vh;
	min-width: 22em;
	max-width: 800px;
}

.header {
	padding: 20px;
	text-align: center;
}

.banner {
	width: 100%;
	min-width: 300px;
	border-radius: 10px;
	background-color: rgba(143, 204, 243, 0.5);
}

.logo {
	width: 260px;
	height: 80px;
}

.pulser {
	position: relative;
	height: calc(100vh - 120px - 10em);
}

.timer {
	position: relative;
	height: 10em;
}

.intro-block {
	margin-top: 1em;
	text-align: center;
}

.intro-carousel {
	margin-bottom: 2em;
}

.btn-start button {
	width: 300px;
}

.footer {
	margin-top: 25px;
	margin-bottom: 5px;
	font-size: 0.6em;
	color: #8d8d8d;
}

</style>
